<template>
  <v-row>
    <v-col
      cols="12"
      class="bg-surface-gradient px-0 mt-1 custom-border-radius-top custom-border-radius-bottom">
      <v-row class="position-relative">
        <v-col cols="9" class="pb-0">
          <span class="font-weight-bold">{{ showFractionType(product.emptoFractionId) }}</span>
          <br />
          {{ t('deliver_to') }}: {{ currentCartDeliveryAddress.postalCode }}
        </v-col>

        <v-col cols="3" class="pb-0 px-0">
          <div class="py-0 position-relative product-icon">
            <v-img
              v-if="product.productSize > 10"
              :alt="t('roll_off_container')"
              height="40px"
              src="@/assets/icons/active-big-container.svg" />
            <v-img
              v-else
              height="40px"
              src="@/assets/icons/active-small-container.svg"
              :alt="t('settling_container')" />
            <span class="font-weight-bold pl-2 d-block position-absolute">
              {{ product.size }}
            </span>
          </div>
        </v-col>

        <v-col cols="4" class="pb-0 pt-0">Container</v-col>
        <v-col cols="8" class="text-right pb-0 pt-0">
          {{ product.size }} {{ product.description }}
        </v-col>

        <v-col cols="6" class="pb-0 pt-0">{{ t('amount') }}</v-col>
        <v-col cols="6" class="text-right pb-0 pt-0">{{ product.quantity }}</v-col>

        <v-col cols="4" class="pt-0 pr-0">{{ t('date') }}</v-col>
        <v-col cols="8" class="text-right pt-0 pl-0">
          {{ formatDateString(product.deliveryDate[0]) }} -
          {{ formatDateString(product.deliveryDate[1]) }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import type { DeliveryAddress, Product } from '~/types/types'

const { t } = useI18n()
const { formatDateString } = useDateUtils()

const fractionStore = useFractionStore()
fractionStore.getFractionData()
const { fractionsSorted } = storeToRefs(fractionStore)

defineProps<{ product: Product; currentCartDeliveryAddress: DeliveryAddress }>()

function showFractionType(id: number): string {
  if (fractionsSorted.value?.length === 0) return 'unbekannt'

  return (
    fractionsSorted.value?.find(fraction => fraction.empto_id === id)?.description ?? 'unbekannt'
  )
}
</script>

<style scoped lang="scss">
.custom-border-radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-border-radius-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.custom-change {
  font-size: 0.8rem;
}
</style>
